var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Supplier Complaints","filters":_vm.filters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('number', 'Number', _vm.number.value)}},model:{value:(_vm.number.value),callback:function ($$v) {_vm.$set(_vm.number, "value", $$v)},expression:"number.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.orderedDate.dateRange),"clearable":"","label":"Created Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.orderedDate.dateRange = []}}},on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'created',
            'Date',
            _vm.orderedDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.orderedDate.dateRange),callback:function ($$v) {_vm.$set(_vm.orderedDate, "dateRange", $$v)},expression:"orderedDate.dateRange"}})],1),_c('v-autocomplete',{attrs:{"items":_vm.statuses,"label":"Status","autocomplete":"off"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('status', 'Status', _vm.status.value)}},model:{value:(_vm.status.value),callback:function ($$v) {_vm.$set(_vm.status, "value", $$v)},expression:"status.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.severities,"label":"Severity","autocomplete":"off"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'severity',
          'Severity',
          _vm.severity.value
        )}},model:{value:(_vm.severity.value),callback:function ($$v) {_vm.$set(_vm.severity, "value", $$v)},expression:"severity.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.departments,"label":"Department","autocomplete":"off"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'department',
          'Department',
          _vm.department.value
        )}},model:{value:(_vm.department.value),callback:function ($$v) {_vm.$set(_vm.department, "value", $$v)},expression:"department.value"}}),_c('v-text-field',{attrs:{"label":"Purchase Order"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'supplierComplaintOrderNumber',
          'Purchase Order',
          _vm.supplierComplaintOrderNumber.value
        )}},model:{value:(_vm.supplierComplaintOrderNumber.value),callback:function ($$v) {_vm.$set(_vm.supplierComplaintOrderNumber, "value", $$v)},expression:"supplierComplaintOrderNumber.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"name","item-value":"name","label":"Raised By","autocomplete":"off","c":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'createdBy',
          'Created By',
          _vm.createdBy.value
        )}},model:{value:(_vm.createdBy.value),callback:function ($$v) {_vm.$set(_vm.createdBy, "value", $$v)},expression:"createdBy.value"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Complaints Timeline "),_c('v-tabs',{attrs:{"right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Status")]),_c('v-tab',[_vm._v("Severity")])],1)],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('supplier-status-chart',{key:_vm.tab + 2})],1),_c('v-tab-item',[_c('supplier-severity-chart',{key:_vm.tab + 1})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticStyle:{"height":"415px"}},[_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('v-img',{staticClass:"my-3",attrs:{"src":require("@/assets/clock.png"),"height":"200","contain":""}}),_c('span',{staticStyle:{"font-size":"30px"}},[_vm._v("Average time to resolve a complaint")]),_c('v-img',{staticClass:"my-3",attrs:{"src":require("@/assets/arrow.png"),"height":"20","contain":""}}),(_vm.hasTotalAverage)?_c('h1',{staticStyle:{"font-weight":"900"}},[_vm._v(" "+_vm._s(_vm.totalAverage)+" day(s) ")]):_vm._e(),(!_vm.hasTotalAverage)?_c('h2',{staticStyle:{"font-weight":"900"}},[_vm._v(" No data available ")]):_vm._e()],1)],1)],1)],1),_c('supplier-complaint-table',{attrs:{"loading":_vm.loading,"allComplaints":_vm.filteredItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }